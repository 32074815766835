import React from "react"
import Layout from "hoc/Layout"
import Header from "containers/Header/Header"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import Font24 from "components/Fonts/Font24"
import Font18 from "components/Fonts/Font18"
import { Grid } from "@mui/material"

const breadcrumbsData = [
  {
    title: "Startseite",
    link: "/",
  },
  "Firma",
]

const Company = () => {
  const { mediraBig, companyMembers } = useStaticQuery(
    graphql`
      query {
        mediraBig: file(name: { eq: "medira-logo-big" }) {
          id
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 95
              placeholder: NONE
            )
          }
        }
        companyMembers: file(name: { eq: "company-members" }) {
          id
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "#e5f5f7"
              quality: 95
              placeholder: NONE
            )
          }
        }
      }
    `
  )

  const titleImage = getImage(mediraBig)
  const companyMembersImage = getImage(companyMembers)

  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle="Firma">
      <Header
        titleImage={titleImage}
        titleImageAlt={"Logo Medira"}
        titleDescription={"Lernen Sie uns näher kennen"}
        titleDescriptionStyles={{
          marginLeft: "64px",
          marginTop: "20px",
          fontWeight: 700,
          fontSize: "18px",
        }}
      />
      <Hr mb={4} />
      <Font42 component="h1" mb={4}>
        Die Firmengeschichte
      </Font42>
      <Font18 textAlign="justify" mb={4}>
        Medira wurde 2014 auf Initiative von Patrycja Mstowska und Beata
        Pozimski gegründet. Die Firmengründerinnen verfügten bereits über
        langjährige Erfahrung in der Altenpflegebranche. Jahrelange Praxis hat
        ihnen gezeigt, was für die Menschen wichtig ist, die als
        Betreuungshelfer arbeiten, aber auch, worauf es bei der Auswahl von
        Betreuungskräften für Kunden ankommt. Medira und ihre Entstehung waren
        nichts anderes als eine Antwort auf die Bedürfnisse des deutschen und
        polnischen Pflegemarktes.
      </Font18>
      <Grid
        container
        columnSpacing={{ xs: 10 }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} lg={6}>
          <Font24 mb={4}>Medira jetzt</Font24>
          <Font18 textAlign="justify" mb={4}>
            Von Anfang an agierte das Unternehmen auf Basis harter Werte und der
            damit begleitenden Mission. Die Funktionsweise steht in direktem
            Zusammenhang mit den Werten:
          </Font18>

          <Font18 textAlign="justify">
            - Gewährleistung der Sicherheit von Kunden und Mitarbeitern,
          </Font18>

          <Font18 textAlign="justify">
            - Flexibilität in Bezug auf das Personal und die Betreuungspersonen,
          </Font18>

          <Font18 textAlign="justify">
            - die höchste Qualität der erbrachten Dienstleistungen,
          </Font18>

          <Font18 textAlign="justify" mb={4}>
            - sich um die Gesundheit der Teammitglieder kümmern, Partnern,
            Betreuungspersonen und Mitarbeitern.
          </Font18>
          <Font18 textAlign="justify" mb={4}>
            Die Mission unseres Unternehmens war es schon immer, den Komfort für
            den Alltag unserer Betreuten zu erhöhen. Wir modifizieren alle
            Aktivitäten, die wir in unserer Organisation unternehmen, durch
            unsere Werte.
          </Font18>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          mb={{ xs: 4, lg: 0 }}
          container
          justifyContent="center"
        >
          <GatsbyImage
            image={companyMembersImage}
            alt="Medira Büromitarbeiter"
          />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Company
